import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useState, useEffect } from 'react'

function CmsImage({ component }) {

    var componentStyle = typeof component.options.image != 'undefined' ? 
    {
        backgroundImage: `url(${component.options.image.url})`,
    } : {};

    return (
        <div className={`h-full image`}>
            <div 
            className={`imageContainer`}
                dangerouslySetInnerHTML={{ __html: component.options.content }} 
                style={componentStyle} 
            />
        </div>
    )
}

export default CmsImage